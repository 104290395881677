
<template>
<div>
  <div v-if="!isLoading" class="row account-page g-4">
    <div class="col-md-4 order-md-2">
      <div class="card position-sticky" style="top: 90px;">
        <svg width="100%" class="card-img-top" viewBox="0 0 193 58" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="0" y1="0.06876508" x2="1" y2="1" id="gradient_1">
              <stop offset="0%" stop-color="#0ec569" />
              <stop offset="100%" stop-color="#8AC2A6" />
            </linearGradient>
          </defs>
          <path d="M0 0L193 0L193 58L0 58L0 0Z" id="Rectangle" fill="url(#gradient_1)" fill-opacity="1" fill-rule="evenodd" stroke="none" />
        </svg>
        <div class="card-body text-center">
          <a href="javascript:void(0)" class="avatar rounded-circle avatar-lg card-avatar card-avatar-top">
            <img :src="absoluteUrl(activeUser.image)" class="avatar-img rounded-circle border border-4 border-card" alt="...">
          </a>
            <small>
              <span class="text-success">●</span> Online
            </small>
          <h3 class="card-title">
            <a href="javascript:void(0)">{{activeUser.first_name}} {{activeUser.last_name}}</a>
          </h3>
          <p class="small text-muted mb-3">
            Joined on {{$filters.date(activeUser.created_at)}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-8">
      <div class="row g-4">
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-tab-profile />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-information />
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="card shadow-sm">
            <div class="card-body">
              <user-edit-tab-password />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else />
</div>
</template>

<script>

import IsLoading from '@/components/IsLoading.vue'
import UserEditTabProfile     from "./UserEditTabProfile.vue"
import UserEditTabPassword from "./UserEditTabPassword.vue"
import UserEditInformation from './UserEditInformation.vue'

export default {
  name: "user-edit",
  components: {
    IsLoading,
    UserEditTabProfile,
    UserEditTabPassword,
    UserEditInformation,
  },
  data(){
    return {
      isLoading: true
    }
  },
  computed: {
    activeUser(){
      return this.$store.state.activeUser
    },
  },
  created() {
    this.$store.dispatch("fetchActiveUser")
      .then((response) => {
        this.isLoading = false
        if(!response.data.success){
          this.$router.push({path: "/error-404"})
        }
    })
  }

}

</script>
<style scoped>
.user-photo{
 width:10rem;
}
</style>
